.AboutContainer {

    padding-top: 5rem !important;

}

.AboutImg {
    width: 80%;
    margin-left: 5rem;
}

.aboutRow {
    width: 80%;
    margin: auto;
    padding-bottom: 70px;

}

.aboutText {
    font-size: 20px;
    margin: auto;
}



.imgCol {
    margin-bottom: 20px;
}

.break {
    height: 200px;
}

.subTitle {
    color: #e83229;
    padding-bottom: 50px;

    width: 50%;
    padding-top: 5%;

    margin: auto;
}

@media only screen and (max-width: 650px) {
    .AboutImg {
     width: 100%;
    margin:auto;
    padding-top: 50px;
  
    }
}