.logoDiv {
    text-align: center !important;
    background: transparent;
    position: absolute;
    width: 100%;
    top: 0.5rem;
}

.language {
    margin-left: 30px;
    margin-top: -38px;
    color: #e83229 !important;
    font-weight: 600;
    text-align: left;
}

.logoStyle2 {
    width: 13rem;
    margin: auto;
    margin-top: -5.5px;
    text-align: center;
}

.navStyle {
    background-color: #e83229 !important;
    width: 100%;
    position: absolute !important;
    top: 4.4rem;
    z-index: 5;
    font-size: 1rem;
    padding: 3px;

}

.navC {
    width: 100% !important;
    text-align: center !important;
}

.navF {
    color: #ffffff !important;
    font-weight: 600;
    /* width: 15%; */
    margin-left: 18rem;
   
}

.navL {
    color: #ffffff !important;
    font-weight: 600;
    margin-left: 4rem;
    /* width: 12%; */
   
}




@media only screen and (max-width: 991px) {
    .navF {
        margin-left: 3rem;
        width: 50%;
    }

    .navL {
        margin-left: 3rem;
        width: 50%;
    }
    .navC {
      text-align: left !important;
    }
}
@media only screen and (max-width: 650px) {
    .logoStyle2 {
        width: 9rem;
        margin-top: 2.5px;

    }

    .language {
        margin-left: 20px;
        margin-top: -25px;
        font-size: 12px;
        font-weight: 600;

    }

    .navF {
        margin-left: 3rem;
        width: 50%;
    }

    .navL {
        margin-left: 3rem;
        width: 50%;
    }
    .navC {
      text-align: left !important;
    }
}