.contactContainer {
    text-align: center !important;
    background: #ff7f502b;
}

.contactRow {
    margin: 20px 100px !important;
    text-align: left;
}

.contactP {
    font-size: 22px;
    color: black;
    text-align: left;
    text-decoration: none;

}

.footer {

    padding: 20px 30px;
    text-align: center;


}

.break-top {

    height: 320px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../img/bg-top.png);

}

.break-bottom {

    height: 320px;
    background-color: #ff7f502b;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../img/bg-bottom.png);

}
.quesBtn{
    font-size: 18px !important;
    text-transform: none !important;
    color: #fff !important;
  margin-bottom: 10px !important;
    padding: 3%;
    background-color: #e83329e9 !important;
}

.quesBtn:hover{
    background-color: #e833295e !important;
    color: #e83329e9 !important;
    font-weight: 600 !important;
}
.sology {
    background: #6e6e6e;
    color: white;
    margin-bottom: 0px;
    font-size: 0.8rem
}

@media only screen and (max-width: 1000px) {
    .break-top {
        height: 140px;
    }
}

@media only screen and (max-width: 650px) {
    .contactRow {
        margin: 20px 10px !important;
    }

    .footer {

        padding: 5px;
        text-align: left;
    }


    .contactP {
        font-size: 16px;
    }
    .quesBtn{
        font-size:14px !important;
   
    }

    .break-top {
        height: 90px;
    }
    .sology {
        font-size: 0.6rem;
    }
}