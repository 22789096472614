.slider {
  max-width: 100% !important;
}

.sliderContainer {
  margin-top: 6.2rem;
}

.sliderBg1 {
  background-image: url(../img/slide0.jpg);
  background-size: cover;
  height: 33rem;
  max-width: 100% !important;
  border: none;
}

.sliderBg2 {
  background-image: url(../img/slide1.jpg);
  background-size: cover;
  height: 33rem;
  max-width: 100% !important;
  border: none;
}

.sliderBg3 {
  background-image: url(../img/slide2.jpg);
  background-size: cover;
  height: 33rem;
  max-width: 100% !important;
  border: none;
}

.sliderBg4 {
  background-image: url(../img/slide4.jpg);
  background-size: cover;
  height: 33rem;
  max-width: 100% !important;
  border: none;
}


@media only screen and (max-width: 650px) {
  .sliderBg1 {
    background-image: url(../img/slide0-mobile.jpg);
    /* height: 37rem; */
  }

  .sliderBg2 {
    background-image: url(../img/slide3-mobile.jpg);
    /* height: 37rem; */
  }

  .sliderBg3 {
    background-image: url(../img/slide1-mobile.jpg);
    /* height: 37rem; */
  }

  .sliderBg4 {
    background-image: url(../img/slide2-mobile.jpg);
    /* height: 37rem; */
  }
}

@media only screen and (min-width: 1600px) {
  .sliderBg1 {
    height: 40rem;
  }

  .sliderBg2 {
    height: 40rem;
  }

  .sliderBg3 {
    height: 40rem;
  }

  .sliderBg4 {
    height: 40rem;
  }
}