.productsContainer {
  margin-top: 120px;
  margin-bottom: 100px;
  text-align: center;
  width: 98% !important;
}



.productSlider {
  height: 14rem;
  margin: auto;
  margin-bottom: 30px;
}


.title {
  color: #e83229;
  padding-bottom: 50px;

}

.productName {
  font-size: 1.2rem;
  color: #e83229;
  font-weight: 600;
}

.productBtn {
  background-color: white !important;
  color: #fb0f03bc !important;
  font-weight: 600 !important;
  text-decoration: none !important;
  padding: 2% 10% !important;
  border: 1px solid !important;
  border-radius: 50px !important;
  border-color: #fb0f03bc !important;
}

.productBtn:hover {
  background-color: #e8332946 !important;
  color: #e83329e9 !important;
}

.diaBtn {
  display: block;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-color: #e83329e9;
  background-color: #e83329e9;
  color: white;
  font-weight: 600;
  font-size: 35px;
  height: 73px;
  width: 200px;
  pointer-events: none;

  -webkit-padding-before: 0px;
  margin-top: 5rem;
}

.slick-arrow {
  display: none !important;
}

.dialogContainer {
  width: 100% !important;
  text-align: center !important;

}

.dialogBox {
  margin: auto !important;
}

.dialogImg {
  max-width: 15rem;
  margin: auto !important;
  padding: 1.5rem !important;

}

.smallImg {
  width: 35rem;
  margin: auto !important;
  padding: 3rem !important;
}

@media only screen and (max-width: 650px) {
  .dialogImg {
    max-width: 10rem;
  }

  .smallImg {
    width: 20rem;
  }

  .diaBtn {
    font-weight: 600;
    font-size: 20px;
    height: 40px;
    width: 150px;
    margin-top: 3rem;
  }


}